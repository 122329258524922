import {
  initializeApp,
  type FirebaseApp,
  type FirebaseOptions
} from 'firebase/app'
import { getFirestore, type Firestore } from 'firebase/firestore'
import { getAnalytics, type Analytics } from 'firebase/analytics'
import { getAuth, type Auth } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyBUZ7eIWEtIcm2cy9ZMjzgCTXYGCrw04ME',
  authDomain: 'ascent-d6e5e.firebaseapp.com',
  databaseURL: 'https://ascent-d6e5e-default-rtdb.firebaseio.com',
  projectId: 'ascent-d6e5e',
  storageBucket: 'ascent-d6e5e.appspot.com',
  messagingSenderId: '690482694379',
  appId: '1:690482694379:web:3d77c9fbe87ee21e00d6ae',
  measurementId: 'G-DDMY8RT7LX'
}

export let app: FirebaseApp
export let auth: Auth
export let analytics: Analytics
export let firestore: Firestore

export function initFirebase() {
  if (!app) {
    app = initializeApp(firebaseConfig)
    auth = getAuth(app)
    analytics = getAnalytics(app)
    firestore = getFirestore(app)
  }
}
