import { initFirebase } from './firebase'
import { initAppAuthPage } from './pages/appAuthPage'
import { initContactPage } from './pages/contactPage'

initFirebase()

document.addEventListener('DOMContentLoaded', () => {
  const pageId = window.location.pathname
  switch (pageId) {
    case '/contact':
      initContactPage()
      break
    case '/app-auth':
      initAppAuthPage()
      break
    default:
      console.log('No JS for this page.')
  }
})

