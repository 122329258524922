import { html } from '../utils'
import { auth } from '../firebase'
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  sendPasswordResetEmail,
  applyActionCode,
  validatePassword
} from 'firebase/auth'
import { AppAuthPageMode, AppAuthPageState } from '../types'

let state: AppAuthPageState

const getParameterByName = (name: string) => {
  const url = window.location.href
  return new URL(url).searchParams.get(name)
}

const filterErrorMessage = (message: string) => {
  if (message.includes('auth/expired-action-code')) {
    return 'Error: This link has expired.'
  } else if (message.includes('auth/invalid-action-code')) {
    return 'Error: This link is invalid.'
  } else {
    return message
      .split(' ')
      .filter((word: string) => !word.toLowerCase().includes('firebase'))
      .join(' ')
      .replace('rror ', 'rror: ')
  }
}

const resetPassword = async () => {
  try {
    if (state.newPassword !== state.confirmPassword) {
      state.responseMessage = 'Passwords do not match.'
      state.responseStatusGood = false
      return
    }
    const passwordValidationStatus = await validatePassword(
      auth,
      state.newPassword
    )
    if (!passwordValidationStatus.isValid) {
      state.responseStatusGood = false
      if (!passwordValidationStatus.meetsMinPasswordLength)
        state.responseMessage = 'Password is too short.'
      else if (!passwordValidationStatus.meetsMaxPasswordLength)
        state.responseMessage = 'Password is too long.'
      else if (
        passwordValidationStatus.passwordPolicy.customStrengthOptions
          .containsLowercaseLetter &&
        !passwordValidationStatus.containsLowercaseLetter
      )
        state.responseMessage =
          'Password must contain one or more lowercase letters.'
      else if (
        passwordValidationStatus.passwordPolicy.customStrengthOptions
          .containsUppercaseLetter &&
        !passwordValidationStatus.containsUppercaseLetter
      )
        state.responseMessage =
          'Password must contain one or more uppercase letters.'
      else if (
        passwordValidationStatus.passwordPolicy.customStrengthOptions
          .containsNumericCharacter &&
        !passwordValidationStatus.containsNumericCharacter
      )
        state.responseMessage = 'Password must contain one or more numbers.'
      else if (
        passwordValidationStatus.passwordPolicy.customStrengthOptions
          .containsNonAlphanumericCharacter &&
        !passwordValidationStatus.containsNonAlphanumericCharacter
      )
        state.responseMessage = 'Password must contain one or more symbols.'
      else state.responseMessage = 'Password is not valid.'
    } else {
      state.responseMessage = ''
    }

    await verifyPasswordResetCode(auth, state.actionCode)
    await confirmPasswordReset(auth, state.actionCode, state.newPassword)
    state.actionFinished = true
    return
  } catch (error: any) {
    state.responseMessage = filterErrorMessage(error.message)
    state.responseStatusGood = false
  }

  const passwordContainerEl = document.getElementById('password-container')
  const newPasswordEl = document.getElementById('new-password')
  const confirmPasswordEl = document.getElementById('confirm-password')

  passwordContainerEl?.classList.add('animate-wiggle')
  newPasswordEl?.classList.add('border-red-500')
  confirmPasswordEl?.classList.add('border-red-500')
  setTimeout(() => {
    passwordContainerEl?.classList.remove('animate-wiggle')
    newPasswordEl?.classList.remove('border-red-500')
    confirmPasswordEl?.classList.remove('border-red-500')
  }, 500)
}

const recoverEmail = async () => {
  try {
    await applyActionCode(auth, state.actionCode)
    state.responseMessage = 'Email recovered successfully!'
    state.responseStatusGood = true
    state.actionFinished = true
  } catch (error: any) {
    state.responseMessage = filterErrorMessage(error.message)
    state.responseStatusGood = false
  }
}

const verifyEmail = async () => {
  try {
    await applyActionCode(auth, state.actionCode)
    state.responseMessage = 'Email verified successfully!'
    state.responseStatusGood = true
    state.actionFinished = true
  } catch (error: any) {
    state.responseMessage = filterErrorMessage(error.message)
    state.responseStatusGood = false
  }
}

const sendResetEmail = async () => {
  try {
    await sendPasswordResetEmail(auth, state.accountEmail)
    state.responseMessage = 'Password reset email sent successfully!'
    state.responseStatusGood = true
  } catch (error) {
    state.responseMessage = 'Error sending password reset email.'
    state.responseStatusGood = false
  }
}

export const initAppAuthPage = async () => {
  const container = document.getElementById(
    'content-container'
  ) as HTMLDivElement

  const handler = {
    set(target: AppAuthPageState, prop: any, value: any) {
      target[prop] = value
      render(container, target)
      return true
    }
  }

  state = new Proxy(
    {
      loading: true,
      mode: 'unset',
      actionCode: '',
      accountEmail: '',
      newPassword: '',
      confirmPassword: '',
      responseMessage: '',
      responseStatusGood: false,
      actionFinished: false,
      pageError: '',
      continueUrl: '',
      lang: 'en'
    },
    handler
  )

  state.mode = (getParameterByName('mode') ?? 'unset') as AppAuthPageMode
  state.actionCode = getParameterByName('oobCode') ?? ''
  state.continueUrl = getParameterByName('continueUrl') ?? ''
  state.lang = getParameterByName('lang') ?? 'en'

  try {
    const info = await checkActionCode(auth, state.actionCode)
    state.accountEmail = info?.data?.email ?? ''
  } catch (error: any) {
    state.pageError = filterErrorMessage(error.message)
  }
  state.loading = false
}

const render = (container: HTMLDivElement, state: AppAuthPageState) => {
  if (state.loading) {
    container.innerHTML = html`
      <svg
        class="animate-spin h-8 w-8"
        viewBox="0 0 100 100"
        fill="none"
        stroke-width="12"
        stroke-linecap="round"
      >
        <circle cx="50" cy="50" r="40" stroke="lightblue" />
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="midnightblue"
          stroke-dasharray="167.47"
        />
      </svg>
    `
  } else if (state.pageError) {
    container.innerHTML = html`<p id="pageError" class="text-xl font-bold">
      ${state.pageError}
    </p>`
  } else if (state.actionFinished) {
    let message = ''
    switch (state.mode) {
      case 'resetPassword':
        message = 'Password reset successful.'
        break
      case 'recoverEmail':
        message = 'Email recovered.'
        break
      case 'verifyEmail':
        message = 'Email verified.'
        break
      default:
        message = ''
    }
    container.innerHTML = html`<p class="text-xl font-bold">${message}</p>`
  } else {
    if (state.mode === 'resetPassword') {
      container.innerHTML = html`
        <form id="authForm" class="flex flex-col">
          <div class="mb-5">
            <p>Reset password ${state.accountEmail ? 'for' : ''}</p>
            <p class="font-bold ${state.accountEmail ? '' : 'hidden'}">
              ${state.accountEmail}
            </p>
          </div>
          <div id="password-container">
            <div>
              <label for="new-password">New Password</label>
              <input
                type="password"
                name="new-password"
                id="new-password"
                value="${state.newPassword}"
                autocomplete="new-password"
              />
            </div>
            <div>
              <label for="confirm-password">Confirm Password</label>
              <input
                type="password"
                name="confirm-password"
                id="confirm-password"
                value="${state.confirmPassword}"
                autocomplete="new-password"
              />
            </div>
          </div>
          <p
            class="h-12 text-center font-semibold mt-2 ${state.responseStatusGood
              ? 'text-green-500'
              : 'text-red-500'}"
          >
            ${state.responseMessage}
          </p>
          <button
            type="submit"
            class="bg-blue-500 border-blue-600 active:bg-blue-600 active:border-blue-700 border-[2px] border-b-[3px] h-12 w-40 rounded-xl text-white font-bold mx-auto"
          >
            Reset Password
          </button>
        </form>
      `
      const form = document.getElementById('authForm') as HTMLFormElement
      form.addEventListener('submit', e => {
        state.newPassword = e.target[0].value
        state.confirmPassword = e.target[1].value
        resetPassword()
        e.preventDefault()
      })
    } else if (state.mode === 'recoverEmail') {
      if (state.actionFinished) {
        container.innerHTML = html`<div class="text-center">
          <p>Your email has been successfully recovered to:</p>
          <p class="font-bold">${state.accountEmail}</p>
          <p>
            If you think your account was compromised, we recommend resetting
            your password.
          </p>
          <button
            type="submit"
            class="bg-blue-500 border-blue-600 active:bg-blue-600 active:border-blue-700 border-[2px] border-b-[3px] h-12 w-48 rounded-xl text-white font-bold mx-auto mt-4"
          >
            Send Password Reset Email
          </button>
        </div>`
        const form = document.getElementById('authForm') as HTMLFormElement
        form.addEventListener('submit', e => {
          sendResetEmail()
          e.preventDefault()
        })
      } else {
        container.innerHTML = html`<div class="text-center">
          <p>Recover your email address:</p>
          <p class="font-bold">${state.accountEmail}</p>
          <button
            type="submit"
            class="bg-blue-500 border-blue-600 active:bg-blue-600 active:border-blue-700 border-[2px] border-b-[3px] h-12 w-48 rounded-xl text-white font-bold mx-auto mt-4"
          >
            Recover Email
          </button>
        </div>`
        const form = document.getElementById('authForm') as HTMLFormElement
        form.addEventListener('submit', e => {
          recoverEmail()
          e.preventDefault()
        })
      }
    } else if (state.mode === 'verifyEmail') {
      if (state.actionFinished)
        container.innerHTML = html`<div class="text-center">
          <p>Your email has been successfully verified!</p>
          <p class="font-bold">${state.accountEmail}</p>
        </div>`
      else {
        container.innerHTML = html`<div class="text-center">
          <p>Verify your email address:</p>
          <p class="font-bold">${state.accountEmail}</p>
          <button
            type="submit"
            class="bg-blue-500 border-blue-600 active:bg-blue-600 active:border-blue-700 border-[2px] border-b-[3px] h-12 w-48 rounded-xl text-white font-bold mx-auto mt-4"
          >
            Verify Email
          </button>
        </div>`
        const form = document.getElementById('authForm') as HTMLFormElement
        form.addEventListener('submit', e => {
          verifyEmail()
          e.preventDefault()
        })
      }
    } else {
      container.innerHTML = html`<p class="text-xl font-bold">
        There seems to be a problem with that link.
      </p>`
    }
  }
}
