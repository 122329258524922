import { firestore } from '../firebase'
import { addDoc, collection } from 'firebase/firestore'

export const initContactPage = () => {
  document
    ?.getElementById('contactForm')
    ?.addEventListener('submit', function (event) {
      event.preventDefault()

      const form = this as HTMLFormElement

      let formData = {
        name: '',
        email: '',
        message: ''
      }

      formData.name = (
        form.elements.namedItem('name') as HTMLInputElement
      ).value
      formData.email = (
        form.elements.namedItem('email') as HTMLInputElement
      ).value
      formData.message = (
        form.elements.namedItem('message') as HTMLTextAreaElement
      ).value

      addDoc(collection(firestore, 'mail'), {
        to: ['help@fitty.ai'],
        cc: [formData.email],
        message: {
          subject: `Fitty Contact Message ${
            formData.name.trim().length > 0 ? `from ${formData.name}` : ''
          }`,
          html: `Hi ${
            formData.name.trim().length > 0 ? `${formData.name}` : 'Fitty user'
          },<br/><br/>
                    Thank you reaching out! We appreciate your engagement and will respond soon.<br/>
                    <div
                    style="
                    border: 2px solid rgb(209,209,214);
                    border-bottom: 3px solid rgb(209,209,214);
                    background-color: rgb(242,242,247);
                    border-radius: 8px;
                    padding: 8px;
                    margin: 8px;
                    margin-top: 16px;
                    "
                    >
                    <b>Message:</b> ${formData.message}<br/><br/>${
                      formData?.name
                    }<br/>${formData?.email}
                    <div>
                    `,
          text: `Hi ${
            formData.name.trim().length > 0 ? `${formData.name}` : 'Fitty user'
          },\n\n
                    Thank you reaching out! We appreciate your engagement and will respond soon.\n
                    \n
                    Message: ${formData.message}\n\n${formData?.name}\n${
                      formData?.email
                    }
                    `
        }
      })
        .then(() => {
          alert('Message sent!')
          form.reset()
        })
        .catch(error => {
          alert('Error sending message. Please try again later.')
          console.error('Error sending message: ', error)
        })
    })
}
